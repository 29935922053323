.grid-script {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-top: 15px;
}
.grid-scriptOffsets {
  display: grid;
  grid-template-columns: 100%;  
margin-top: 24px;
}


.grid-searcher-script {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;  
  gap: 10px;

}

@media (max-width: 600px) {
  .grid-searcher-script {
    display: grid;
    grid-template-columns: 1fr ;  
    gap: 10px;
 }
}

.card-content-script {
  background: #ffffff !important;
  border-radius: 20px !important;
}

@media screen and (max-width: 992px) {
  .card-content-script {
    padding: 0px !important;
  }
}

@media screen and (min-width: 576px) {
  .card-content-script {
    padding: 18px 34px 28px 34px !important;
  }
}

.grid-form-scriptOffsets {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.grid-form-scriptOffsets .full-width {
  grid-column: span 2;
}

.grid-form-scriptOffsets .buttonScript {
  grid-column: span 2;
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 576px) {
  .grid-form-scriptOffsets {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.grid-label-scriptOffsets {
  display: block;
  margin-bottom: 5px;
  color: #041370;
  font-size: 14px;
}
/* Searcher */
.grid-searcher {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

}

.select-searcher {
  width: 100%;
}

.item-searcher {}

.custom-select {
  color: black;
  font-weight: bold;
  font-family: "MontserratSemiBold";
  font-size: 14px;
}

@media screen and (min-width: 576px) {
  .grid-searcher {
      display: grid;
      grid-template-columns: 1fr auto;
  }

  .select-searcher {
      width: 100%;
  }
}

.select-searcher .ant-select-selection-placeholder {
  color: #B6B0AE;
  font-family: "MontserratRegular";
  font-size: 14px;
}

.scriptOff-actions{
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-origin{
  padding-left: 5px;
  /* padding-right: 5px; */
}