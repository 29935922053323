.select-delete .ant-select-selector {
  border-color: red !important;
  width: 100%;
}

.dropdown {
  /* margin-left: 5px; */
  font-weight: bold !important;
}

@media screen and (max-width: 992px) {
  .dropdown {
    /* margin-left: 1px; */
  }
}

.role {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.children {
  margin-top: 10px;
}

.role {
  cursor: pointer;
}

.listRole {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check-outlined {
  color: #00dd9e !important;
  margin-left: 2px;
}
.grid-user {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}
@media screen and (max-width: 768px) {
  .grid-user {
    grid-template-columns: 1fr;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  .ant-descriptions-item {
    span: 1; /* Asigna el span por defecto para pantallas pequeñas */
  }
}

.item-user {
  display: flex;
  justify-content: center;
}

/* Estilos adicionales para alinear correctamente los elementos */
.custom-select .ant-select-arrow {
  color: red;
}
.item-user {
  display: flex;
  justify-content: center;
}
.grid-searcher-user {
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: flex-start;
}
.searcher-user {
  display: grid;
  grid-template-columns: 58% 42%;
  gap: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .searcher-user {
    grid-template-columns: 1fr;
  }
}

/* @media screen and (max-width: 768px) {
  .searcher-user{
    grid-template-columns: 1fr 1fr ;
  }
} */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .searcher-user {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}
.searcher-user-select {
  width: 100%;
  min-width: 100%;
}

.custom-select .ant-select-arrow {
  color: red;
}
