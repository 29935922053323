.container-emission {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: "MontserratBold";
}

@media screen and (max-width: 992px) {
  .container-emission {
    margin-left: 0px;
    font-family: "MontserratBold";
    justify-content: center;
  }
}

@media screen and (min-width: 576px) {
  .container-emission {
    font-family: "MontserratBold";
  }
}
