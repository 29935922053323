
.grid-owners {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-top: 15px;
}
.form-owners {
    font-family: "MontserratRegular";
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }

@media screen and (max-width: 992px) {
    .grid-owners {}
}

.card-owners {
    background: #ffffff !important;
    border-radius: 20px !important;
}

@media screen and (max-width: 992px) {
    .card-owners {
        padding: 0px !important;
    }
}

@media screen and (min-width: 576px) {
    .card-owners {
        padding: 0px 34px 28px 34px !important;
    }
}

.my-projects {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.text-my-projects {
    font-family: "MontserratBold";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #041370;
}

.icon-style {
    color: green !important;
    font-size: 24px !important;
}

.separator-style {
    margin-left: 4px !important;
}

.owners-title {
    margin-bottom: 10px;
}
.delete-ipfs{
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-ipfs{
    padding: 0px 11px;
}
.button-info{
    padding-left: 5px;
    padding-right: 5px;
}
.horizontal-form-generals {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    width: 100%;
  }
  .form-group-generals {
    width: 100%;
  }
  .form-button-generals{
    width: 100%;
    grid-column: 1 / -1;
  }
  .form-label-generals {
    width: 100%;
  }
  .form-select-generals{
    display: flex;
    flex-direction: column;
  }
  .button-generals{
    width: 100%;
  }
  .ant-select-item-option {
    font-family: "MontserratMedium";
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #041370;
    color: #fff;
    font-weight:normal;
  }
  
.button-image-project{
  display: flex;
  align-items: center;
}
