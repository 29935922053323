.main_login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-wrap: wrap;
  background-color: rgba(109, 108, 110, 0.171);
}

.form_login {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(138, 129, 129);
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 20px;
  justify-content: center; 
  align-content: center; 
}


@media screen and (max-width: 992px) {
  .ant-form-item-control-input {
    width: 100%;
  }
}

@media screen and (min-width: 576px) {
  .ant-form-item-control-input {
    width: 100%;
  }
}

.button {
  border-radius: 15px;
}

.container {
  text-align: center;
  border-radius: 4px;
}
.root_login{
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin: 2em;

}
.restore_password{

  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  justify-content: center; 
  align-content: center; 
  text-align: center;
}