.space-dash {
  background: #ffd9d9;
  border-radius: 7px;
  padding: 5px 9px 5px 9px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 0px 0px;
  margin-left: -10px;
}

@media screen and (max-width: 992px) {
  .space-dash {
    padding: 2px;
  }
}

@media screen and (min-width: 576px) {
  .space-dash {}
}

.space-below {
  background: rgba(46, 214, 163, 0.15);
  border-radius: 7px;
  padding: 5px 9px 5px 9px;
}

@media screen and (max-width: 992px) {
  .space-below {
    padding: 2px;
  }
}

@media screen and (min-width: 576px) {
  .space-below {}
}

.migrations-dash {
  background: rgba(46, 214, 163, 0.15);
  border-radius: 7px;
  border: solid 1px rgba(46, 214, 163, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00a389 !important;
  padding: 4px 10px 4px 10px;
  margin: auto;
}

.migrations-dash:hover,
.migrations-dash:focus,
.migrations-dash:active {
  border: solid 1px #00a389;
  background: rgba(46, 214, 163, 0.15);
}

.delete-dash {
  background: #f0f7fb;
  border-radius: 7px;
  border: solid 1px #ff1e46;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff1e46 !important;
  padding: 4px 10px 4px 10px;
  margin: auto;
}

.delete-dash:hover,
.delete-dash:focus,
.delete-dash:active {
  border: solid 1px #ff1e46;
  background: #f0f7fb;
}

@media screen and (max-width: 992px) {
  .migrations-dash {
    padding: 2px 8px;
  }
}

@media screen and (min-width: 576px) {
  .migrations-dash {}
}

.root-dash {
  background: #f0f7fb !important;
  border-radius: 8px;
  padding: 0.5em !important;
  margin-top: 5px;
}

@media screen and (max-width: 992px) {
  .root-dash {
    padding: 2px;
  }
}

@media screen and (min-width: 576px) {
  .root-dash {}
}

.text-migration {
  font-family: "MontserratMedium";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #00a389;
}

@media screen and (max-width: 992px) {
  .text-migration {}
}

@media screen and (min-width: 576px) {
  .text-migration {}
}

.ant-notification-notice {
  box-shadow: 2px 2px 22px 1px #b6c8dd;
  border-radius: 10px;
  max-height: 530px;
  overflow-y: scroll;
  width: 100%;
}