.blue-containter {
  border-radius: 10px;
  width: 100%;
  min-height: 300px;
  height: auto;
  padding: 10px;
  padding-top: 30px;
  background: linear-gradient(90deg, #1d19a2 0%, #1d19a2 100%);
}

.blue-header {
  height: 100px;
  width: auto;
}

.blue-item {
  min-height: 20px;
  height: auto;
  width: auto;
}

.ant-divider {
  line-height: 3px;
  margin: 3px;
  background: #ffffff;
  color: #00a389;
}