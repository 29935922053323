.white-card {
  border-radius: 20px;
  max-width: 278.64px;
  height: 122px;
  background: #f0f5f8;
  box-shadow: 0px 4px 22px 5px #b6c8dd;
}

.value-container {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.value-text {
  color: #00dd9e;
  font-family: "MontserratBold";
  font-size: 30px;
  font-weight: 700;
  margin-right: 10px;
}

.unit-text {
  color: #ffffff;
  font-family: "MontserratRegular";
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
}

.description-text {
  color: #ffffff;
  font-family: "MontserratSemiBold";
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
}

.white-card .unit-text {
  color: #0319a3;
  font-family: "MontserratRegular";
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
}

.white-card .description-text {
  color: #0319a3;
  font-family: "MontserratSemiBold";
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
}

.blue-card {
  border-radius: 20px;
  max-width: 278.64px;
  height: 122px;
  background: linear-gradient(90deg, #041370 0%, #4100f3 100%);
  box-shadow: 0px 4px 22px 5px #b6c8dd;
}

.green-card {
  border-radius: 20px;
  max-width: 278.64px;
  height: 122px;
  background: #00dd9e;
  box-shadow: 0px 4px 22px 5px #b6c8dd;
}

.green-card .value-text {
  color: #f0f7fb;
  font-family: "MontserratBold";
  font-size: 30px;
  font-weight: 700;
  margin-right: 10px;
}

.dark-green-card {
  border-radius: 20px;
  max-width: 278.64px;
  height: 122px;
  background: linear-gradient(270deg, #00dd9e -38.6%, #041370 100%);
  box-shadow: 0px 4px 22px 5px #b6c8dd;
}

.dark-degraded-card {
  border-radius: 20px;
  max-width: 278.64px;
  height: 122px;
  background: #026282;
  box-shadow: 0px 4px 22px 5px #b6c8dd;
}

@media (max-width: 1260px) {
  .white-card {
    /* height: 150px; */
  }

  .blue-card {
    /* height: 150px; */
  }

  .green-card {
    /* height: 150px; */
  }

  .dark-green-card {
    /* height: 150px; */
  }

  .dark-degraded-card {
  }

  .ant-card-body {
    padding: 16px;
  }
}

@media (max-width: 720px) {
  .ant-card-body {
    padding: 12px;
  }

  .value-text {
    font-size: 18px;
  }

  .blue-card .value-text {
    font-size: 18px;
  }

  .green-card .value-text {
    font-size: 18px;
  }

  .dark-green-card .value-text {
    font-size: 18px;
  }

  .dark-degraded-card .value-text {
    font-size: 18px;
  }
}
