.scope-container {
  height: 122px;
  margin-right: 13px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.title-scope {
  font-family: "MontserratBold";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #041370;
}

.subtitle-scope {
  font-family: "MontserratRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #041370;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
  .title-scope {
  color: #00dd9e;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
  .subtitle-scope {
  color: #ffffff;
}

.progress-bar-container {
  width: 121.98px;
  margin-top: -5px;
}

.progress-bar-container .my-progress-bar .ant-progress-bg {
  height: 5px !important;
}

@media screen and (max-width: 360px) {
  .scope-container {
    height: 142px;
  }
}

.ant-progress-circle .ant-progress-text {
  color: #041370;
  font-weight: 700;
  font-size: 13px;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
  .ant-progress-circle
  .ant-progress-text {
  color: #f7f8f9;
}

.ant-progress-circle-trail {
  stroke: #f7f8f9;
  stroke-width: 3px;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
  .ant-progress-circle-trail {
  stroke: #dadee3;
}
