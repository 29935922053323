.assignation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 37px;
  height: 37px;
  border-radius: 8px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #8fa0b6;
  color: #041370;
}

.ant-checkbox {
  margin-right: 12px;
}

.ant-checkbox .ant-checkbox-inner {
  padding: 8px;
  border-radius: 5px;
  border-color: #041370;
  opacity: 0.5;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(0, 221, 158);
  border-color: rgb(0, 221, 158);
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
}

.ant-checkbox-wrapper {
  font-family: "MontserratRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  color: #041370;
  text-align: left;
}

.ant-picker-input input::placeholder {
  font-family: "MontserratRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #041370;
}

.ant-picker {
  font-family: "MontserratRegular";
}

.add-checkbox {
  font-family: "MontserratRegular";
  font-style: normal;
  font-weight: 500;
  /* font-size: 14px; */
  color: #041370;
  margin-bottom: 12px;
}

.ant-picker-active-bar {
  /* display: none; */
  /* background: green !important; */
}

.ant-picker-separator {}

.usernameAssignation {
  color: #041370 !important;
  font-family: "MontserratBold";
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  font-style: normal !important;
}

.containerAssignation {
  padding: 7px !important;
  display: flex;
  margin: 0px !important;
}

.containerTextAssignation {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
  margin-left: 12px !important;
}

.containerRightAssignation {
  display: flex !important;
  align-items: flex-start !important;
  margin-left: 4px !important;
}

.occupationAssignation {
  font-family: "MontserratBold !important";
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #041370 !important;
  font-style: normal !important;
  letter-spacing: 0.02em !important;
  opacity: 0.8 !important;
}