.wrapper {
  width: 310px;
  height: 48px;
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  font-family: "MontserratRegular";
  line-height: 18px;
}

.actionBtns {
  width: 80%;
  background-color: #dfe8f4;
  margin: 2em;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-radius: 26px;
}

.actionBtn {
  padding: 1em;
  width: 50%;
  outline: none;
  border: none;
  background-color: transparent;
  border-radius: 26px;
  /* color: #fff; */
  color: #041370;

  font-weight: 400;
  cursor: pointer;
}

.moveBtn {
  position: absolute;
  width: 50%;
  height: 100%;
  /* margin: 2px; */
  border: none;
  outline: none;
  border-radius: 26px;
  background: linear-gradient(90deg, #041370 0%, #4100f3 100%);
  transform: translateX(0);
  transition: all 0.2s ease-in-out;
  color: #ffffff;
}

.rightBtn {
  transform: translateX(98%);
  transition: all 0.2s ease-in-out;
}
