.grid-permissions {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-top: 15px;
}

@media screen and (max-width: 992px) {
    .grid-permissions {}
}

.card-permissions {
    background: #ffffff !important;
    border-radius: 20px !important;
}

@media screen and (max-width: 992px) {
    .card-permissions {
        padding: 0px !important;
    }
}

@media screen and (min-width: 576px) {
    .card-permissions {
        padding: 0px 34px 28px 34px !important;
    }
}

.my-permissions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.text-my-permissions {
    font-family: "MontserratBold";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #041370;
}

.permissions-title {
    margin-bottom: 10px;
}
.flexAccess {
    display: grid;
    width: 200px;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    row-gap: 100px;
    padding: 0px;
    border: 1px solid #ccc;
    align-items: center;
    justify-items: center;
    margin-bottom: 2px;
  }
  @media (max-width: 767px) {
    .flexAccess {
      width: 100%;
    }
  }
  
  .columnLeft {
    justify-self: end;
    text-align: end;
    white-space: normal;
    word-wrap: break-word;
    max-width: 100%; 
  }
  
  .columnRight {
    justify-self: start;
  }
  
  .containerAccess {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flexAccess > div {
    overflow: hidden;
  }