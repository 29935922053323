.manageData {
    margin-top: 1.4em;
}

/* NewManageData */
.grid-NewManageData {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 30px;

}

.picker-NewManageDate {
    width: 100%;

}

.input-NewManageData {
    width: 100%;

}

.item-NewManageData {}

@media screen and (min-width: 576px) {
    .grid-NewManageData {
        display: grid;
        grid-template-columns: 1fr 1fr auto;

    }

    .picker-NewManageDate {
        width: 100%;
    }

    .input-NewManageData {
        width: 100%;
    }
}

.picker-NewManageDate input::placeholder {
    color: #B6B0AE;
    font-family: "MontserratRegular";
    font-size: 14px;

}

.input-NewManageData::placeholder {
    color: #B6B0AE;
    font-family: "MontserratRegular";
    font-size: 14px;

}

.custom-date-picker .ant-picker-input {
    color: black;
    font-weight: bold;
    font-family: "MontserratSemiBold";
    font-size: 14px;
    font-variant: tabular-nums;

}

.custom-input {
    color: black;
    font-weight: bold;
    font-family: "MontserratSemiBold";
    font-size: 14px;
    font-variant: tabular-nums;
}

/* Searcher */
.grid-searcher {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}

.select-searcher {
    width: 100%;
}

.item-searcher {}

.custom-select {
    color: black;
    font-weight: bold;
    font-family: "MontserratSemiBold";
    font-size: 14px;
    font-variant: tabular-nums;

}

@media screen and (min-width: 576px) {
    .grid-searcher {
        display: grid;
        grid-template-columns: 1fr auto;
    }

    .select-searcher {
        width: 100%;
    }
}

.select-searcher .ant-select-selection-placeholder {
    color: #B6B0AE;
    font-family: "MontserratRegular";
    font-size: 14px;
}

.form-searcher {
    margin-bottom: 30px;
}

.table-manage-data {
    margin-bottom: 30px;
}