.grid-file {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-top: 15px;
}

@media screen and (max-width: 992px) {
  .grid-file {
  }
}

.card-file {
  background: #ffffff !important;
  border-radius: 20px !important;
}

@media screen and (max-width: 992px) {
  .card-file {
    padding: 0px !important;
  }
}

@media screen and (min-width: 576px) {
  .card-file {
    padding: 0px 34px 28px 34px !important;
  }
}
.text-files {
  font-family: "MontserratBold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #041370;
}
.content-files{
display: flex;
flex-direction: row;
align-items: center;
margin-bottom: 20px;  
}
.icon-files{
  color: green;
  font-size: 24px;

}
.container-document-file {
display: grid;
grid-template-columns: 1fr ;
gap: 10px;
align-items: center;
justify-items: center;
}

@media screen and (max-width: 992px) {
.container-document-file {
  /* grid-template-columns: auto; */
  grid-template-columns: 1fr;
}
}

@media screen and (min-width: 576px) {
.container-document-file {
}
}

.container-document-file-item {
}