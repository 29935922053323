body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "MontserratLight";
  src: local("MontserratLight"),
    url("fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MontserratRegular";
  src: local("Montserrat"),
    url("fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
    url("fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"),
    url("fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MontserratExtraBold";
  src: local("MontserratExtraBold"),
    url("fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-display: swap;
}
