.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content div {
}

.button {
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.document {
  display: flex;
  justify-content: center;
  max-width: 100%;
}

@media screen and (min-width: 576px) {
  .content div {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 992px) {
  .content div {
    display: flex;
    align-items: center;
  }
}

.create {
  display: flex;
  justify-content: space-between;
  padding: 0 30px 10px 0;
}
