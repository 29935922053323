.form-container {
  font-family: "MontserratRegular";
  display: flex;
  justify-content: center;
}

.ant-form-item-label > label {
  font-style: normal;
  font-weight: 500px;
  font-size: 14px;
  line-height: 17px;
  color: #041370;
}

.grid-emission {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-top: 15px;
}

@media screen and (max-width: 992px) {
  .grid-emission {
  }
}

.card-content {
  background: #ffffff !important;
  border-radius: 20px !important;
}

@media screen and (max-width: 992px) {
  .card-content {
    padding: 0px !important;
  }
}

@media screen and (min-width: 576px) {
  .card-content {
    padding: 0px 34px 28px 34px !important;
  }
}

.ant-modal-confirm-title {
  font-family: "MontserratRegular";
  font-weight: bold;
}

.ant-modal-confirm-content {
  font-family: "Montserrat";
  font-size: 8px;
  line-height: 20px;
  color: #041370;
}

.ant-modal-confirm-btns {
  font-family: "MontserratRegular";
}

.update {
  display: flex;
  justify-content: flex-end;
}
