.ant-notification-notice {
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(62, 73, 84, 0.04);
  margin-top: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .ant-notification-notice {}
}

@media screen and (min-width: 576px) {
  .ant-notification-notice {
    /* height: 265px; */
  }
}

.iconButton {
  background: transparent;
}