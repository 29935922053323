.ant-radio-button-wrapper:first-child {
  border-radius: 12px;
  border-left: 1px solid #dfe8f4;
}
.ant-radio-button-wrapper:not(:first-child):before {
  width: 0px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 12px;
  border-color: #dfe8f4;
}
.radio-btn-container {
  width: 220px;
}
.title-container {
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-radio-button-wrapper {
  color: #041370;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #dfe8f4;
  height: 96px;
  font-family: "MontserratMedium";
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin-top: 14px;
}
.ant-radio-button-wrapper:hover {
  color: #041370;
  border-color: #dfe8f4;
}
.ant-radio-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 9px;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: linear-gradient(90deg, #041370 0%, #4100f3 100%);
  border-color: #dfe8f4;
  border-radius: 12px;
  text-align: center;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  background: linear-gradient(90deg, #041370 0%, #4100f3 100%);
  border-color: #dfe8f4;
  border-radius: 12px;
  text-align: center;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within {
  box-shadow: 0 0 0 0;
}

@media screen and (max-width: 720px) {
  .ant-radio-group {
    justify-content: center;
  }
}

@media screen and (min-width: 1512px) {
  .title-container {
    height: 106px;
  }
  .ant-radio-button-wrapper {
    height: 106px;
  }
  .ant-radio-group {
    flex-wrap: nowrap;
  }
}
