.my-progress-bar .ant-progress-bg {
  background-color: #00dd9e !important;
  border-radius: 30px;
  position: absolute;
  height: 18px;
}

.my-progress-bar .ant-progress-inner {
  position: static;
  background-color: #dadee3;
  height: 5px;
  display: flex;
  align-items: center;
}

.ant-progress-bg {
  border-radius: 0px 16px 16px 0px;
}

.ant-progress-inner {
  border-radius: 0px 16px 16px 0px;
}

.root-quantity {
  width: 38%;
}

@media screen and (max-width: 992px) {
  .root-quantity {
    width: 100%;
  }
}

@media screen and (min-width: 576px) {
  .root-quantity {
  }
}
