.section-container {
  background: linear-gradient(90deg, #041370 0%, #4100f3 100%);
  border-radius: 12px;
}

@media screen and (max-width: 992px) {
  .section-container {
  }
}

@media screen and (min-width: 576px) {
  .section-container {
    height: 348px;
    /* width: 685px; */
  }
}

.space {
  padding-top: 10px;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .space {
  }
}

@media screen and (min-width: 576px) {
  .space {
    width: 490px;
    margin: 28px 50px 46px 50px;
  }
}

.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .root {
    /* margin: 0px 5px 0px 5px; */
  }
}

@media screen and (min-width: 576px) {
  .root {
  }
}
