.search {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-bottom: 1em;
  margin-top: 2em;
}

@media screen and (max-width: 992px) {
  .search-item {
    width: 100%;
  }
}

@media screen and (min-width: 576px) {
  .search-item {
    width: 40%;
  }
}
