.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 40px;
}
@media screen and (min-width: 1281px) {
  .grid-container {
    grid-template-columns: 50% 50%; 
  }
}
@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 576px) {
  .grid-container {
  }
}

.grid-item {
}

.grid-business {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-top: 40px;
}

@media screen and (max-width: 992px) {
  .grid-business {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 576px) {
  .grid-business {
  }
}

.card-content {
  background: #ffffff !important;
  border-radius: 20px !important;
}

@media screen and (max-width: 992px) {
  .card-content {
    padding: 0px !important;
  }
}

@media screen and (min-width: 576px) {
  .card-content {
    padding: 0px 54px 48px 54px !important;
  }
}

.tons-dash {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 992px) {
  .tons-dash {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (min-width: 576px) {
  .tons-dash {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
