.button-elem {
  background-color: #041370;
  border-radius: 26px;
  color: #ffffff;
  text-align: center;
  font-family: "MontserratSemiBold";
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  height: 32px;
  margin-top: 0px;
}
.button-elem:hover,
.button-elem:focus {
  background-color: #041370;
  color: #ffffff;
}
.button-white-blue {
  background-color: #ffffff;
  border: 1px solid #0319a3;
  border-radius: 13px;
  box-sizing: border-box;
  color: #0319a3;
  text-align: center;
  font-family: "MontserratSemiBold";
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  height: 42px;
}
.button-white-blue:hover,
.button-white-blue:focus {
  border: 1px solid #0319a3;
  background-color: #ffffff;
  color: #0319a3;
}
.button-disabled:disabled {
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 13px;
  box-sizing: border-box;
  color: #818181;
  text-align: center;
  font-family: "MontserratSemiBold";
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  height: 42px;
}
.button-disabled:disabled:hover,
.button-disabled:disabled:focus {
  background: #ffffff;
  border: 1px solid #cacaca;
  color: #818181;
}
