.boxMaps{
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 400px;
}

/* .boxMain{
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 4px; 
    border-radius: 10px;
    background-color: red;
    box-shadow: inset;
    min-width: 200px;
    z-index: 10;  
} */