.title-section {
  margin-top: 28px;
}
.section-content {
  margin-top: 18px;
  background: #ffffff !important;
  border-radius: 20px !important;
}

@media screen and (max-width: 992px) {
  .section-content {
    padding: 20px !important;
  }
}

@media screen and (min-width: 576px) {
  .section-content {
    padding: 0px 54px 68px 54px !important;
  }
}
.title-style {
  font-family: "MontserratBold";
  font-weight: 700;
  font-size: 20px;
  color: #041370;
}
.subtitle-style {
  font-family: "MontserratSemiBold";
  font-weight: 600;
  font-size: 18px;
  color: #041370;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 992px) {
  .subtitle-style {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (min-width: 576px) {
  .subtitle-style {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.item-container {
  margin-top: 42px;
  display: flex;
  justify-content: center;
}
